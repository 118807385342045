import { BlockContainerWithBackground } from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { ButtonLink } from '@klokgroep/shared-components/src/Button';
import { captureUnderscoreErrorException } from '@sentry/nextjs';
import { getDataForHostname } from 'utils/hostnames';
import { getPropertiesWithPreviewAndSitewideData } from '@klokgroep/shared-components/utils/getPropertiesWithPreviewNavigationAndSocials';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { useCommonTranslations } from '@klokgroep/shared-components/utils/useTranslations';
import NextErrorComponent, { ErrorProps } from 'next/error';
import type { NextPage } from 'next';

const CustomErrorComponent: NextPage<ErrorProps> = ({ statusCode }) => {
  const t = useCommonTranslations();

  return statusCode === 404 ? (
    <BlockContainerWithBackground backgroundColor="gray">
      <MaxWidth>
        <RichText>
          <h1>{t('not_found_title')}</h1>
          <p>{t('not_found_description')}</p>
          <ButtonLink href="/">{t('not_found_button_label')}</ButtonLink>
        </RichText>
      </MaxWidth>
    </BlockContainerWithBackground>
  ) : (
    <NextErrorComponent statusCode={statusCode} />
  );
};

CustomErrorComponent.getInitialProps = async (context) => {
  // This will contain the status code of the response
  const initialProperties = await NextErrorComponent.getInitialProps(context);

  if (initialProperties?.statusCode === 404) {
    // If this is running on the client, we cannot run `getPropertiesWithPreviewAndSitewideData`, so we use the props already available on the window scope.
    if (typeof window !== 'undefined') {
      return window.__NEXT_DATA__.props.pageProps;
    }

    const host = context.req?.headers['x-forwarded-host'] || context.req?.headers.host;
    const data = await getDataForHostname(host?.toString());

    const properties = await getPropertiesWithPreviewAndSitewideData(
      undefined,
      undefined,
      data,
      undefined,
      data?.siteId
    );

    return { ...initialProperties, ...properties, siteId: data?.siteId };
  }

  // In case this is running in a serverless function, await this in order to give Sentry
  // time to send the error before the lambda exits
  if (initialProperties?.statusCode !== 404) {
    // In case this is running in a serverless function, await this in order to give Sentry
    // time to send the error before the lambda exits
    await captureUnderscoreErrorException(context);
  }

  return initialProperties;
};

export default CustomErrorComponent;
