import { getCorporatePages } from '@klokgroep/sanity';
import { SITES, SiteIdType } from '@klokgroep/sanity/src/sites';

const corporateSites = SITES.filter(({ type }) => type === 'corporate');

export async function getDataForHostname(hostname?: string) {
  return corporateSites.find(
    (item) =>
      item.productionHostname === `https://${hostname}` ||
      item.acceptanceHostname === `https://${hostname}` ||
      item.previewHostname === `https://${hostname}` ||
      item.developmentHostname === `http://${hostname}`
  );
}

export async function getCorporatePageSlugsWithSiteId(siteId?: SiteIdType, filterNoIndex?: boolean) {
  const pages = await getCorporatePages(undefined, siteId, filterNoIndex);
  return pages.map((page) => ({ params: { siteId: page.siteId, slugs: page.slugs } }));
}

export function getCorporateSiteIdPaths() {
  return corporateSites.map(({ siteId }) => ({ params: { siteId } }));
}
