import { SITES, SiteIdType, getClient } from '@klokgroep/sanity';
import { groq } from 'next-sanity';

type LocaleOption = keyof NonNullable<Sanity.Schema.LocaleString>;

export type PropertiesWithMessages<T> = { messages: Awaited<ReturnType<typeof getMessages>> } & T;

export async function withMessagesForLocale<T>(
  properties: T,
  siteId: SiteIdType = 'holding',
  preview = false
): Promise<PropertiesWithMessages<T>> {
  const locale = SITES.find((site) => site.siteId === siteId)?.locale || 'nl';
  const messages = await getMessages(locale, preview);
  return { ...properties, messages };
}

const isLocaleString = (value: any): value is Sanity.Schema.LocaleString =>
  typeof value === 'object' && value !== null && value._type === 'localeString';

async function getMessages(locale: LocaleOption | string = 'nl', preview = false) {
  const dictionaryItems = await getClient(preview).fetch<Sanity.Schema.Dictionary>(
    groq`*[_type == 'dictionary' && _id == 'dictionary'][0]`
  );

  if (!dictionaryItems) {
    return {};
  }

  const translationKeys = Object.keys(dictionaryItems).filter((key) => !key.startsWith('_'));

  const common = translationKeys.reduce<{ [key: string]: string }>((accumulator, key) => {
    const item = dictionaryItems[key as keyof typeof dictionaryItems];
    if (!isLocaleString(item)) {
      return accumulator;
    }
    accumulator[key] = item[locale as keyof typeof item] || key;
    return accumulator;
  }, {});

  const messages = { common };
  return messages;
}
