import { getSitewideData, SiteIdType } from '@klokgroep/sanity';
import { undefinedToNull } from './undefinedToNull';
import { withMessagesForLocale } from './withMessagesForLocale';

export type PropertiesWithPreviewNavigationAndSocialsAndMetaData<T> = PropertiesWithMetaData<
  PropertiesWithPreview<PropertiesWithSitewideData<T>>
>;

export const getPropertiesWithPreviewAndSitewideData = async <T>(
  preview: boolean | undefined,
  previewData: PreviewData | undefined,
  properties: T,
  metaData?: MetaData | undefined,
  siteId?: SiteIdType
): Promise<PropertiesWithPreviewNavigationAndSocialsAndMetaData<T>> =>
  undefinedToNull(
    withPreview(
      preview,
      previewData,
      await withMessagesForLocale(await withSitewideData(withMetaData(properties, metaData), siteId), siteId, preview)
    )
  );

// Preview
interface PreviewData {
  token?: string;
  query?: string;
  params?: any;
}

export type PropertiesWithPreview<T> = {
  preview?: boolean;
  previewData?: PreviewData;
} & T;

const withPreview = <T>(
  preview: boolean | undefined,
  previewData: PreviewData | undefined,
  properties: T
): PropertiesWithPreview<T> => ({
  ...properties,
  preview,
  previewData,
});

type PropertiesWithSitewideData<T> = Awaited<ReturnType<typeof getSitewideData>> & T;

const withSitewideData = async <T>(properties: T, siteId?: SiteIdType): Promise<PropertiesWithSitewideData<T>> => {
  const sitewideData = await getSitewideData(false, siteId);
  return { ...properties, ...sitewideData };
};

interface MetaData {
  title?: string;
  description?: string;
  image?: string;
  noIndex?: boolean;

  schema?: {
    type?: 'WebPage' | 'Article' | 'CollectionPage' | 'ContactPage';
    socials?: { [key: string]: string };
    organisation?: {
      name?: string;
      legalName?: string;
      description?: string;
      logo?: {
        url?: string;
        width?: number;
        height?: number;
      };
      founderName?: string;
      foundingDate?: string;
      numberOfEmployees?: number;
      slogan?: string;
    };
  };
}

type PropertiesWithMetaData<T> = {
  metaData?: MetaData;
} & T;

const withMetaData = <T>(properties: T, metaData?: MetaData): PropertiesWithMetaData<T> => ({
  metaData,
  ...properties,
});
