// This function turns every undefined value to null in an object. This is needed in JSON serialization in getStaticProps and other SSR fn's.
export function undefinedToNull(value: any): any | null {
  if (Array.isArray(value)) {
    return value.map((valueInArray) => undefinedToNull(valueInArray));
  }

  if (value !== null && typeof value === 'object') {
    return Object.fromEntries(Object.entries(value).map(([k, v]) => [k, undefinedToNull(v)]));
  }

  // eslint-disable-next-line unicorn/no-null
  return value === undefined ? null : value;
}
